
import {
  defineComponent,
  ref,
  onMounted,
  onActivated,
  onUpdated,
  computed,
} from "vue";
// import { MBDatatablePlus } from "magicbean-saas-common";
import MBDatatablePlus from "@/components/mb-datatable/DataTablePlus.vue";
// import FilterDropdwon from "./FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiEntityRelationships } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import mixin from "@/mixins";
import {
  commonExportFile,
  formatDate,
  formatUtcDate,
  setModuleBCN,
  commonDefaultDate,
  settlementLastMonth,
  settlementLastMonthDate,
  settlementLastMonthDateString,
  settlementLastWeekDate,
  settlementLastWeekDateString,
  settlementLastYear,
  settlementLastYearDate,
  settlementLastQuarterDate,
  settlementLastDay,
  settlementLastDayDate,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import axios, { CancelTokenSource } from "axios";
import {
  FilterOption,
  SortOption,
  SortCounter,
  TaggingItem,
  TableHeader,
} from "@/core/directive/interface/common";
import { checkPermissionFlag } from "@/directives/permission";
import CommonAgentAccountOption from "@/components/layout/CommonAgentAccountOption.vue";
import { CommonDateType } from "@/core/directive/type/common";
import moment from "moment";
import { EntityRelationAgentAccounts } from "@/core/directive/interface/entityRelationships";
import { useComputedFn } from "@/core/directive/function/order";

type EntityRelationshipsType =
  | "supplier"
  | "buyer"
  | "clientele"
  | "payee"
  | "rebate"
  | "exporter"
  | "taxAgent"
  | "retailerBuyer";

interface EntityRelationshipsConfig {
  agent_account_type: string;
  title: string;
  all: string;
  active: string;
  quantity?: string;
  net?: string;
  total?: string;
  grossTotal?: string;
  taxTotal?: string;
  refundableTotal?: string;
  refundedTotal?: string;
  paidTotal?: string;
  quantityItems?: string;
  refNo?: string;
  accountName?: string;
  tablePayAccount?: string;
  tableDayDate?: string;
  tableDate?: string;
  tableQuantityItems?: string;
  tableNetTotal?: string;
  tableAmount?: string;
  tableGrossAmount?: string;
  tableTaxAmount?: string;
  tableRefundableAmount?: string;
  tableRefundedAmount?: string;
  tablePaidAmount?: string;
  responseList: any;
  responseExport: any;
}

export default defineComponent({
  name: "transaction-report-finacial-report-ocps-ss-sales-receivables",
  props: {
    type: {
      type: String as () => EntityRelationshipsType,
      default: "supplier",
    },
  },
  components: {
    MBDatatablePlus,
    // FilterDropdwon,
    // PermissionCommon,
    CommonAgentAccountOption,
  },
  setup(props) {
    const { t, locale } = useI18n();
    const { showServerErrorMsg } = mixin();
    const route = useRoute();

    const detailLoading = ref(false);
    const tableLoading = ref(false);
    const statisticPicker = ref();
    const filterRef = ref();
    const disabledExport = ref(false);
    const tableData = ref<Array<any>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const disabledExportSelected = ref(false);
    const disabledExportAll = ref(false);
    const disabledExportLastWeek = ref(false);
    const disabledExportLastMonth = ref(false);
    const checkedArr = ref<Array<number>>([]);
    const sortOrder = ref("desc");
    const sortLabel = ref("");
    const sortOptions = ref<Array<SortOption>>([
      { field: "business_date", direction: "desc" },
      { field: "settlement_entity_id", direction: "asc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);

    const getTypeParams = computed(() => {
      let params: EntityRelationshipsConfig = {
        agent_account_type: "",
        title: "",
        all: "",
        active: "",
        tableAmount: "",
        responseList: undefined,
        responseExport: undefined,
      };
      switch (props.type) {
        case "supplier":
          params = {
            agent_account_type: "supplier",
            title: t("entityRelationships.suppliersBreakdown"),
            all: t("entityRelationships.allSuppliers"),
            active: t("entityRelationships.activeSuppliers"),
            quantity: t("entityRelationships.quantitySuppliers"),
            total: t("entityRelationships.purchaseTotal"),
            quantityItems: t("entityRelationships.quantityItemsPurchased"),
            refNo: t("entityRelationships.supplierRefNo"),
            accountName: t("entityRelationships.supplierName"),
            tableDate: t("entityRelationships.businessCycle"),
            tableQuantityItems: t(
              "entityRelationships.tableQuantityItemsPurchased"
            ),
            tableAmount: t("entityRelationships.purchaseAmount"),
            responseList: ApiEntityRelationships.getSupplierList,
            responseExport: ApiEntityRelationships.exportSupplier,
          };
          break;
        case "buyer":
        case "retailerBuyer":
          params = {
            agent_account_type: "buyer",
            title: t("entityRelationships.buyersBreakdown"),
            all: t("entityRelationships.allBuyers"),
            active: t("entityRelationships.activeBuyers"),
            quantity: t("entityRelationships.quantityBuyers"),
            total: t("entityRelationships.salesTotal"),
            quantityItems: t("entityRelationships.quantityItemsSold"),
            refNo: t("entityRelationships.buyerRefNo"),
            accountName: t("entityRelationships.buyerName"),
            tableDate: t("entityRelationships.businessCycle"),
            tableQuantityItems: t("entityRelationships.tableQuantityItemsSold"),
            tableAmount: t("entityRelationships.salesAmount"),
            responseList: ApiEntityRelationships.getBuyerList,
            responseExport: ApiEntityRelationships.exportBuyer,
          };
          if (props.type === "retailerBuyer") {
            params.responseList = ApiEntityRelationships.getRetailerBuyerList;
            params.responseExport = ApiEntityRelationships.exportRetailerBuyer;
          }
          break;
        case "clientele":
          params = {
            agent_account_type: "supplier",
            title: t("entityRelationships.clientelesBreakdown"),
            all: t("entityRelationships.allClienteles"),
            active: t("entityRelationships.activeClienteles"),
            quantity: t("entityRelationships.quantityClienteles"),
            taxTotal: t("entityRelationships.declarationTotal"),
            refundableTotal: t("entityRelationships.refundableTotal"),
            refundedTotal: t("entityRelationships.refundedTotal"),
            refNo: t("entityRelationships.clienteleRefNo"),
            accountName: t("entityRelationships.clienteleName"),
            tableDate: t("entityRelationships.taxCycle"),
            tableTaxAmount: t("entityRelationships.declarationAmount"),
            tableRefundableAmount: t("entityRelationships.refundableAmount"),
            tableRefundedAmount: t("entityRelationships.refundedAmount"),
            responseList: ApiEntityRelationships.getClienteleList,
            responseExport: ApiEntityRelationships.exportClientele,
          };
          break;
        case "payee":
          params = {
            agent_account_type: "payee",
            title: t("entityRelationships.payeesBreakdown"),
            all: t("entityRelationships.allPayees"),
            active: t("entityRelationships.activePayees"),
            quantity: t("entityRelationships.quantityPayees"),
            paidTotal: t("entityRelationships.paymentTotal"),
            refNo: t("entityRelationships.payeeRefNo"),
            accountName: t("entityRelationships.payeeName"),
            tablePayAccount: t("entityRelationships.payeeAccount"),
            tableDate: t("entityRelationships.financialCycle"),
            tableDayDate: t("entityRelationships.paymentDate"),
            tablePaidAmount: t("entityRelationships.paymentAmount"),
            responseList: ApiEntityRelationships.getPayeeList,
            responseExport: ApiEntityRelationships.exportPayee,
          };
          break;
        case "rebate":
          params = {
            // agent_account_type: "service_recipient",
            agent_account_type: "supplier",
            title: t("entityRelationships.rebateBreakdown"),
            all: t("entityRelationships.allSuppliers"),
            active: t("entityRelationships.activeSuppliers"),
            quantity: t("entityRelationships.quantityClienteles"),
            net: t("entityRelationships.purchaseTotal"),
            total: t("entityRelationships.rebateTotal"),
            grossTotal: t("entityRelationships.rebateTotalI"),
            refNo: t("entityRelationships.clienteleRefNo"),
            accountName: t("entityRelationships.clienteleName"),
            tableDate: t("entityRelationships.financialCycle"),
            tableNetTotal: t("entityRelationships.transactionAmount"),
            tableAmount: t("entityRelationships.rebateAmount"),
            tableGrossAmount: t("entityRelationships.rebateAmountI"),
            responseList: ApiEntityRelationships.getRebateList,
            responseExport: ApiEntityRelationships.exportRebate,
          };
          break;
        case "exporter":
          params = {
            agent_account_type: "buyer",
            title: t("entityRelationships.exportersBreakdown"),
            all: t("entityRelationships.allExporters"),
            active: t("entityRelationships.activeExporters"),
            quantity: t("entityRelationships.quantityExporters"),
            total: t("entityRelationships.exportTotal"),
            quantityItems: t("entityRelationships.quantityItemsExported"),
            refNo: t("entityRelationships.exporterRefNo"),
            accountName: t("entityRelationships.exporterName"),
            tableDate: t("entityRelationships.businessCycle"),
            tableQuantityItems: t(
              "entityRelationships.tableQuantityItemsExported"
            ),
            tableAmount: t("entityRelationships.exportAmount"),
            responseList: ApiEntityRelationships.getExporterList,
            responseExport: ApiEntityRelationships.exportExporter,
          };
          break;
        case "taxAgent":
          params = {
            agent_account_type: "buyer",
            title: t("entityRelationships.taxAgentsBreakdown"),
            all: t("entityRelationships.allAgents"),
            active: t("entityRelationships.activeAgents"),
            quantity: t("entityRelationships.quantityTaxAgents"),
            taxTotal: t("entityRelationships.declarationTotal"),
            refundableTotal: t("entityRelationships.refundableTotal"),
            refundedTotal: t("entityRelationships.refundedTotal"),
            refNo: t("entityRelationships.taxAgentRefNo"),
            accountName: t("entityRelationships.taxAgentName"),
            tableDate: t("entityRelationships.taxCycle"),
            tableTaxAmount: t("entityRelationships.declarationAmount"),
            tableRefundableAmount: t("entityRelationships.refundableAmount"),
            tableRefundedAmount: t("entityRelationships.refundedAmount"),
            responseList: ApiEntityRelationships.getTaxAgentList,
            responseExport: ApiEntityRelationships.exportTaxAgent,
          };
          break;
        default:
          // eslint-disable-next-line no-case-declarations
          const n: never = props.type;
          console.log(n);
          break;
      }
      return params;
    });

    const getTopGridColumnCount = computed(() => {
      let count = 0;
      if (getTypeParams.value.quantity) {
        count += 1;
      }
      if (getTypeParams.value.total) {
        count += 1;
      }
      if (getTypeParams.value.taxTotal) {
        count += 1;
      }
      if (getTypeParams.value.refundableTotal) {
        count += 1;
      }
      if (getTypeParams.value.refundedTotal) {
        count += 1;
      }
      if (getTypeParams.value.paidTotal) {
        count += 1;
      }
      if (getTypeParams.value.net) {
        count += 1;
      }
      if (getTypeParams.value.grossTotal) {
        count += 1;
      }
      if (getTypeParams.value.quantityItems) {
        count += 1;
      }
      return count;
    });

    const isPushMiddleDate = computed(() => {
      return (
        props.type === "supplier" ||
        props.type === "buyer" ||
        props.type === "retailerBuyer" ||
        props.type === "exporter"
      );
    });

    const tableHeader = computed(() => {
      let arr: TableHeader[] = [];
      if (getTypeParams.value.refNo) {
        arr.push({
          name: getTypeParams.value.refNo,
          key: "settlement_entity_id",
        });
      }
      if (getTypeParams.value.accountName) {
        arr.push({
          name: getTypeParams.value.accountName,
          key: "account_name",
        });
      }
      if (getTypeParams.value.tablePayAccount) {
        arr.push({
          name: getTypeParams.value.tablePayAccount,
          key: "payment_account",
          sortable: false,
        });
      }
      if (getTypeParams.value.tableDate) {
        if (isPushMiddleDate.value) {
          arr.push({
            name: getTypeParams.value.tableDate,
            key: "business_date",
          });
        }
      }
      if (getTypeParams.value.tableQuantityItems) {
        arr.push({
          name: getTypeParams.value.tableQuantityItems,
          key: "transaction_product_count",
          align: "right",
        });
      }
      if (getTypeParams.value.tableTaxAmount) {
        arr.push({
          name: getTypeParams.value.tableTaxAmount,
          key: "transaction_tax_amount",
          align: "right",
        });
      }
      if (getTypeParams.value.tableRefundableAmount) {
        arr.push({
          name: getTypeParams.value.tableRefundableAmount,
          key: "refundable_amount",
          align: "right",
        });
      }
      if (getTypeParams.value.tableRefundedAmount) {
        arr.push({
          name: getTypeParams.value.tableRefundedAmount,
          key: "refunded_amount",
          align: "right",
        });
      }
      if (getTypeParams.value.tablePaidAmount) {
        arr.push({
          name: getTypeParams.value.tablePaidAmount,
          key: "paid_amount",
          align: "right",
          offset: "100px",
        });
      }
      if (getTypeParams.value.tablePayAccount) {
        arr.push({
          name: t("entityRelationships.purposePayment"),
          key: "purpose_payment",
          sortable: false,
        });
      }
      if (getTypeParams.value.tableNetTotal) {
        arr.push({
          name: getTypeParams.value.tableNetTotal,
          key: "transaction_net_total",
          align: "right",
        });
      }
      if (getTypeParams.value.tableAmount) {
        arr.push({
          name: getTypeParams.value.tableAmount,
          key: "transaction_net_amount",
          align: "right",
        });
      }
      if (getTypeParams.value.tableGrossAmount) {
        arr.push({
          name: getTypeParams.value.tableGrossAmount,
          key: "transaction_gross_amount",
          align: "right",
        });
      }
      if (getTypeParams.value.tableDate) {
        if (!isPushMiddleDate.value) {
          const name =
            props.type === "payee" &&
            options.value.dateType === "day" &&
            getTypeParams.value.tableDayDate
              ? getTypeParams.value.tableDayDate
              : getTypeParams.value.tableDate;
          arr.push({
            name: name,
            key: "business_date",
            align: "right",
          });
        }
      }
      return arr;
    });

    const defaultSourceOptions = computed(() => {
      if (props.type === "payee") {
        return [
          {
            label: getTypeParams.value.active,
            value: "activated",
          },
        ];
      }
      return [
        {
          label: getTypeParams.value.active,
          value: "activated",
        },
        {
          label: getTypeParams.value.all,
          value: "all",
        },
      ];
    });

    const defaultDateTypeOptions = computed(() => {
      let options = [
        {
          label: t("entityRelationships.month"),
          value: "month",
        },
        {
          label: t("entityRelationships.year"),
          value: "year",
        },
      ];
      if (props.type === "payee") {
        options.unshift({
          label: t("entityRelationships.day"),
          value: "day",
        });
      }
      return options;
    });

    const defaultDateType = props.type === "payee" ? "day" : "month";

    const options = ref({
      defaultSort: [...sortOptions.value] as SortOption[],
      filter_group: [] as FilterOption[],
      summary: {
        agent_count: 0,
        total_transaction_net_amount: 0,
        total_transaction_gross_amount: 0,
        total_transaction_product_count: 0,
        total_transaction_tax_amount: 0,
        transaction_net_amount: 0,
        total_refundable_amount: 0,
        total_refunded_amount: 0,
        total_paid_amount: 0,
      },
      business_date: [] as CommonDateType[],
      dateType: defaultDateType,
      dateTypeOptions: [...defaultDateTypeOptions.value],
      source: "activated",
      cancelSource: null as CancelTokenSource | null,
      sourceLoading: false,
      sourceOptions: [
        ...defaultSourceOptions.value,
      ] as EntityRelationAgentAccounts[],
      searchOptions: [] as TaggingItem[],
      filter_date: "business_date",
      totalArray: [] as any[],
    });

    const getDateType = computed(() => {
      let format = "YYYY-MM-DD",
        valueFormat = "YYYY-MM-DD";
      if (options.value.dateType === "month") {
        format = "YYYY-MM";
      } else if (options.value.dateType === "year") {
        format = "YYYY";
      }
      return {
        format,
        valueFormat,
      };
    });

    const allDate = [
      new Date("2022-02-01T00:00:00.000Z"),
      settlementLastDayDate({
        value: 0,
        isStart: false,
        format: getDateType.value.valueFormat,
      }),
    ];

    const dayShortcuts = [
      {
        text: t("common.today"),
        value: () => {
          return [
            settlementLastDayDate({
              value: 0,
              format: getDateType.value.valueFormat,
            }),
            settlementLastDayDate({
              value: 0,
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.yesterday"),
        value: () => {
          return [
            settlementLastDayDate({
              format: getDateType.value.valueFormat,
            }),
            settlementLastDayDate({
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.lastWeekDays"),
        value: () => {
          return [
            settlementLastDayDate({
              value: -7,
              format: getDateType.value.valueFormat,
            }),
            settlementLastDayDate({
              value: 0,
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.lastMonthDays"),
        value: () => {
          return [
            settlementLastDayDate({
              value: -31,
              format: getDateType.value.valueFormat,
            }),
            settlementLastDayDate({
              value: 0,
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.thisMonth"),
        value: () => {
          return [
            settlementLastMonthDate({
              value: 0,
              format: getDateType.value.valueFormat,
            }),
            settlementLastDayDate({
              value: 0,
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.lastMonth"),
        value: () => {
          return [
            settlementLastMonthDate({
              value: -1,
              format: getDateType.value.valueFormat,
            }),
            settlementLastMonthDate({
              value: -1,
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.all"),
        value: () => {
          return allDate;
        },
      },
    ];

    const monthShortcuts = [
      {
        text: t("common.thisMonth"),
        value: () => {
          return [
            settlementLastMonthDate({
              value: 0,
              format: getDateType.value.valueFormat,
            }),
            settlementLastDayDate({
              value: 0,
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.thisQuarter"),
        value: () => {
          return [
            settlementLastQuarterDate({
              value: 0,
              format: getDateType.value.valueFormat,
            }),
            settlementLastDayDate({
              value: 0,
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.thisYear"),
        value: () => {
          return [
            settlementLastYearDate({
              value: 0,
              format: getDateType.value.valueFormat,
            }),
            settlementLastDayDate({
              value: 0,
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.lastMonth"),
        value: () => {
          return [
            settlementLastMonthDate({
              value: -1,
              format: getDateType.value.valueFormat,
            }),
            settlementLastMonthDate({
              value: -1,
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.lastQuarter"),
        value: () => {
          return [
            settlementLastQuarterDate({
              value: -1,
              format: getDateType.value.valueFormat,
            }),
            settlementLastQuarterDate({
              value: -1,
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.lastYear"),
        value: () => {
          return [
            settlementLastYearDate({
              value: -1,
              format: getDateType.value.valueFormat,
            }),
            settlementLastYearDate({
              value: -1,
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.all"),
        value: () => {
          return allDate;
        },
      },
    ];

    const yearShortcuts = [
      {
        text: t("common.thisYear"),
        value: () => {
          return [
            settlementLastYearDate({
              value: 0,
              format: getDateType.value.valueFormat,
            }),
            settlementLastDayDate({
              value: 0,
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.lastYear"),
        value: () => {
          return [
            settlementLastYearDate({
              value: -1,
              format: getDateType.value.valueFormat,
            }),
            settlementLastYearDate({
              value: -1,
              isStart: false,
              format: getDateType.value.valueFormat,
            }),
          ];
        },
      },
      {
        text: t("common.all"),
        value: () => {
          return allDate;
        },
      },
    ];

    const disabledDate = (time: Date) => {
      const startDate = new Date("2022-01-31");
      return (
        time.getTime() > Date.now() || time.getTime() < startDate.getTime()
      );
    };
    const disabledYear = (time) => {
      const startDate = new Date("2022-02-01");
      return time > new Date().getFullYear() || time < startDate.getFullYear();
    };

    const lastWeekFilter = computed(() => {
      const commonFilter = filterOptions.value;
      const resultFilter = commonFilter.filter((item) => {
        return !item.field.match(options.value.filter_date);
      });
      return {
        ...setCommonFilter.value,
        filter_group: [
          ...setOtherFilter.value,
          ...resultFilter,
          {
            field: options.value.filter_date,
            value: settlementLastWeekDateString({}),
            condition: "gteq",
          },
          {
            field: options.value.filter_date,
            value: settlementLastWeekDateString({
              isStart: false,
            }),
            condition: "lteq",
          },
        ],
      };
    });

    const lastMonthFilter = computed(() => {
      const commonFilter = filterOptions.value;
      const resultFilter = commonFilter.filter((item) => {
        return !item.field.match(options.value.filter_date);
      });
      return {
        ...setCommonFilter.value,
        filter_group: [
          ...setOtherFilter.value,
          ...resultFilter,
          {
            field: options.value.filter_date,
            value: settlementLastMonthDateString({}),
            condition: "gteq",
          },
          {
            field: options.value.filter_date,
            value: settlementLastMonthDateString({
              isStart: false,
            }),
            condition: "lteq",
          },
        ],
      };
    });

    const setOtherFilter = computed(() => {
      let arr: FilterOption[] = [];
      if (options.value.business_date.length > 0) {
        arr.push({
          field: options.value.filter_date,
          value: options.value.business_date[0]!,
          condition: "gteq",
        });
        arr.push({
          field: options.value.filter_date,
          value: options.value.business_date[1]!,
          condition: "lteq",
        });
      }
      return arr;
    });

    const setCommonFilter = computed(() => {
      return {
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        sort_orders: sortOptions.value,
        filter_group: [...filterOptions.value, ...setOtherFilter.value],
        view_data_mode: options.value.dateType,
        agent_account: options.value.source,
      };
    });

    const isCanExport = computed((): boolean => {
      return checkPermissionFlag({ auth: ["export"] });
    });

    /**
     * @description: 请求数据
     */
    const getList = async (showLoading?: "tableLoading" | "detailLoading") => {
      if (showLoading === "tableLoading") {
        tableLoading.value = true;
      } else if (showLoading === "detailLoading") {
        detailLoading.value = true;
        tableLoading.value = true;
      }

      const { data } = await getTypeParams.value.responseList(
        setCommonFilter.value
      );

      if (showLoading === "tableLoading") {
        tableLoading.value = false;
      } else if (showLoading === "detailLoading") {
        detailLoading.value = false;
        tableLoading.value = false;
      }

      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        // tableData.value.forEach((item, index) => {
        //   item.downloadDisabled = false;
        // });
        total.value = data.data.total;
        if (data.data.summary) {
          options.value.summary = data.data.summary;
        }
        // options.value.totalArray = [];
        // options.value.totalArray.push({
        //   index: 3,
        //   string: new Array(11),
        //   span: Array.from({ length: 11 }).map(() => 1),
        // });
        // options.value.totalArray.push({
        //   index: 6,
        //   string: new Array(11),
        //   span: Array.from({ length: 11 }).map(() => 1),
        // });
        // options.value.totalArray[0].string[0] = "Total Cast";
        // options.value.totalArray[0].span[0] = 2;
        // options.value.totalArray[0].span[1] = 0;
        // options.value.totalArray[0].string[6] = `小计：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;€ 14.391.301,59`;
        // options.value.totalArray[0].span[6] = 2;
        // options.value.totalArray[0].span[5] = 0;
        // options.value.totalArray[0].span[1] = 0;
        // options.value.totalArray[1].string[0] = 2;
        // console.log(options.value.totalArray);
      }
    };

    /**
     * @description: 获取列表
     */
    const updateList = (showLoading?: "tableLoading" | "detailLoading") => {
      currentPage.value = 1;
      getList(showLoading);
    };

    /**
     * @description: 重置过滤器（只修改参数）
     */
    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    /**
     * @description: 重置过滤器（拉取数据）
     */
    const handleFilterReset = () => {
      resetFilter();
      updateList("detailLoading");
    };

    /**
     * @description: 显示默认供应商数据源（默认只修改参数，传true拉取数据）
     */
    const showDefaultOptions = (isUpdate = false) => {
      options.value.sourceOptions.splice(
        0,
        options.value.sourceOptions.length,
        ...defaultSourceOptions.value
      );
      if (isUpdate) {
        updateList("detailLoading");
      }
    };

    /**
     * @description: 重置排序（拉取数据）
     */
    const handleSortReset = () => {
      resetColumnSort();
      updateList("tableLoading");
    };

    /**
     * @description: 重置排序（只修改参数）
     */
    const resetColumnSort = () => {
      sortOptions.value.splice(
        0,
        sortOptions.value.length,
        ...options.value.defaultSort
      );
      sortOrder.value = "desc";
      sortLabel.value = "";
    };

    /**
     * @description: 判断是否是默认视图
     */
    const isDefaultView = computed(() => {
      return (
        JSON.stringify(options.value.defaultSort) ===
          JSON.stringify(sortOptions.value) &&
        sortCounterFlag.length === 0 &&
        options.value.dateType === defaultDateType &&
        JSON.stringify(options.value.filter_group) ===
          JSON.stringify(setCommonFilter.value.filter_group) &&
        options.value.source === "activated"
      );
    });

    const sortCounterFlag = [] as SortCounter[];

    /**
     * @description: 排序独立计数
     */
    const sortCounter = (field: string) => {
      if (sortCounterFlag.some((item) => item.field === field)) {
        sortCounterFlag.forEach((item) => {
          if (item.field === field) {
            item.counter = item.counter + 1;
          }
        });
      } else {
        sortCounterFlag.splice(0, sortCounterFlag.length, {
          field: field,
          counter: 1,
        });
      }
    };

    /**
     * @description: 排序3次判断
     */
    const onSortCounterThree = (val) => {
      let resetFlag = false;
      sortCounterFlag.forEach((item) => {
        if (item.field === val.columnName) {
          if (item.counter === 3) {
            sortCounterFlag.splice(0, sortCounterFlag.length);
            resetFlag = true;
          }
        }
      });
      return resetFlag;
    };

    /**
     * @description: 时间段选择器，结束时间格式化到月底最后一天
     */
    const formatDatePickerEndDay = () => {
      if (options.value.business_date.length === 2) {
        const tempDate = moment(
          commonDefaultDate(options.value.business_date[1])
        );
        if (options.value.dateType === "month") {
          // 获取tempDate的月底最后一天
          const lastDayOfMonth = tempDate.endOf("month");
          const lastDayOfMonthString = lastDayOfMonth.format(
            getDateType.value.valueFormat
          );
          // 与今天比较
          if (
            lastDayOfMonthString <
            moment().format(getDateType.value.valueFormat)
          ) {
            options.value.business_date[1] = lastDayOfMonthString;
          } else {
            options.value.business_date[1] = moment().format(
              getDateType.value.valueFormat
            );
          }
        } else if (options.value.dateType === "year") {
          // 获取tempDate的年底最后一天
          const lastDayOfYear = moment(tempDate).endOf("year");
          const lastDayOfYearString = lastDayOfYear.format(
            getDateType.value.valueFormat
          );
          // 与今天比较
          if (
            lastDayOfYearString < moment().format(getDateType.value.valueFormat)
          ) {
            options.value.business_date[1] = lastDayOfYearString;
          } else {
            options.value.business_date[1] = moment().format(
              getDateType.value.valueFormat
            );
          }
        }
      }
    };

    /**
     * @description: 切换颗粒度选择器时（默认拉取数据，传false只修改参数）
     */
    const defaultDate = (isUpdate = true) => {
      if (options.value.dateType === "day") {
        options.value.business_date = [
          settlementLastMonthDate({
            value: 0,
            format: getDateType.value.valueFormat,
          }),
          settlementLastDayDate({
            value: 0,
            isStart: false,
            format: getDateType.value.valueFormat,
          }),
        ];
      } else if (options.value.dateType === "month") {
        const currentMonth = settlementLastDay({
          value: 0,
        }).get("month");
        options.value.business_date = [
          settlementLastMonthDate({
            value: -currentMonth,
            format: getDateType.value.valueFormat,
          }),
          settlementLastDayDate({
            value: 0,
            isStart: false,
            format: getDateType.value.valueFormat,
          }),
        ];
      } else {
        const currentYear = settlementLastYear({ value: 0 }).get("year");
        options.value.business_date = [
          settlementLastYearDate({
            value: 0,
            format: getDateType.value.valueFormat,
          }),
          settlementLastDayDate({
            value: 0,
            isStart: false,
            format: getDateType.value.valueFormat,
          }),
        ];
        statisticPicker.value?.updateYearRange(currentYear, currentYear);
      }
      if (isUpdate) {
        resetColumnSort(); //重置排序
        updateList("tableLoading");
      }
    };

    /**
     * @description: 切换月选择器时
     */
    const handleFilter = (val) => {
      // let filterArr: Array<FilterOption> = [];
      // for (let item in val) {
      //   if (val[item] != "" && val[item] != null) {
      //     if (val[item] != "" && val[item] != null) {
      //       if (item.includes(options.value.filter_date)) {
      //         const isStart = item.includes("_start") ? true : false,
      //           date = isStart
      //             ? commonChangeFilterAddDate(val[item])
      //             : commonChangeFilterAddDate(val[item], 1);
      //         filterArr.push({
      //           field: options.value.filter_date,
      //           value: date,
      //           condition: isStart ? "gteq" : "lteq",
      //         });
      //       } else {
      //         filterArr.push({
      //           field: item,
      //           value: val[item],
      //           condition: "eq",
      //         });
      //       }
      //     }
      //   }
      // }
      // filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      resetColumnSort(); // 重置排序
      formatDatePickerEndDay(); // 结束时间格式化
      updateList("detailLoading");
    };

    /**
     * @description: 切换年选择器时
     */
    const updateTimeRange = (start, end) => {
      const currentYear = settlementLastYear({ value: 0 }).get("year");
      options.value.business_date = [
        settlementLastYearDate({
          value: start - currentYear,
          format: getDateType.value.valueFormat,
        }),
        settlementLastYearDate({
          value: (end ? end : start) - currentYear,
          isStart: false,
          format: getDateType.value.valueFormat,
        }),
      ];
      statisticPicker.value?.updateYearRange(start, end ? end : start);

      resetColumnSort(); //重置排序
      updateList("tableLoading");
    };

    /**
     * @description: 切换供应商选择器时
     */
    const handleSourceSelect = (val) => {
      resetFilter();
      resetColumnSort();
      if (val === "") {
        options.value.sourceOptions.splice(
          0,
          options.value.sourceOptions.length,
          ...defaultSourceOptions.value
        );
        options.value.source = "activated";
      }
      updateList("detailLoading");
    };

    /**
     * @description: 供应商选择器失去焦点时
     */
    const handleSourceBlur = (val) => {
      if (!val) {
        // 关闭下拉框时
        if (
          options.value.source === "activated" ||
          options.value.source === "all"
        ) {
          showDefaultOptions();
        }
      }
    };

    /**
     * @description: 重置按钮点击时（拉取数据）
     */
    const reset = () => {
      options.value.dateType = defaultDateType;
      defaultDate(false);
      resetColumnSort();
      sortCounterFlag.splice(0, sortCounterFlag.length);
      options.value.source = "activated";
      showDefaultOptions();
      updateList("detailLoading");
    };

    /**
     * @description: 切换排序时
     */
    const onColumnSort = (val: any) => {
      sortCounter(val.columnName);
      const resetFlag = onSortCounterThree(val);
      if (resetFlag) {
        handleSortReset();
      } else {
        let thisDirection = "";
        sortCounterFlag.forEach((item) => {
          if (item.field === val.columnName) {
            if (item.counter === 1) {
              thisDirection = "asc"; //第一次正序
            }
            if (item.counter === 2) {
              thisDirection = "desc"; //第二次倒序
            }
          }
        });
        sortOrder.value = thisDirection;
        const newSortRule = [] as SortOption[];
        if (val.columnName === "settlement_entity_id") {
          newSortRule.push(
            { field: "business_date", direction: "desc" },
            { field: "settlement_entity_id", direction: thisDirection }
          );
        } else if (val.columnName === "account_name") {
          newSortRule.push(
            { field: "business_date", direction: "desc" },
            { field: "account_name", direction: thisDirection }
          );
        } else if (val.columnName === "business_date") {
          newSortRule.push(
            { field: "business_date", direction: thisDirection },
            { field: "settlement_entity_id", direction: "asc" }
          );
        } else if (val.columnName === "transaction_product_count") {
          newSortRule.push(
            { field: "business_date", direction: "desc" },
            { field: "transaction_product_count", direction: thisDirection },
            { field: "settlement_entity_id", direction: "asc" }
          );
        } else if (val.columnName === "transaction_net_amount") {
          newSortRule.push(
            { field: "business_date", direction: "desc" },
            { field: "transaction_net_amount", direction: thisDirection },
            { field: "settlement_entity_id", direction: "asc" }
          );
        } else if (val.columnName === "transaction_gross_amount") {
          newSortRule.push(
            { field: "business_date", direction: "desc" },
            { field: "transaction_gross_amount", direction: thisDirection },
            { field: "settlement_entity_id", direction: "asc" }
          );
        } else if (val.columnName === "transaction_net_total") {
          newSortRule.push(
            { field: "business_date", direction: "desc" },
            { field: "transaction_net_total", direction: thisDirection },
            { field: "settlement_entity_id", direction: "asc" }
          );
        } else if (val.columnName === "refunded_amount") {
          newSortRule.push(
            { field: "business_date", direction: "desc" },
            { field: "refunded_amount", direction: thisDirection },
            { field: "settlement_entity_id", direction: "asc" }
          );
        } else if (val.columnName === "paid_amount") {
          newSortRule.push(
            { field: "business_date", direction: "desc" },
            { field: "paid_amount", direction: thisDirection },
            { field: "settlement_entity_id", direction: "asc" }
          );
        } else if (val.columnName === "refundable_amount") {
          newSortRule.push(
            { field: "business_date", direction: "desc" },
            { field: "refundable_amount", direction: thisDirection },
            { field: "settlement_entity_id", direction: "asc" }
          );
        } else if (val.columnName === "transaction_tax_amount") {
          newSortRule.push(
            { field: "business_date", direction: "desc" },
            { field: "transaction_tax_amount", direction: thisDirection },
            { field: "settlement_entity_id", direction: "asc" }
          );
        }
        sortOptions.value.splice(0, sortOptions.value.length, ...newSortRule);
        sortLabel.value = val.columnName;
        updateList("tableLoading");
      }
    };

    /**
     * @description: 切换页码时
     */
    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getList("tableLoading");
    };

    /**
     * @description: 切换记录条数时
     */
    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      updateList("tableLoading");
    };

    const debounceSearch = _.debounce(updateList, 1000);

    const searchItems = () => {
      debounceSearch();
    };

    const removeSearch = () => {
      search.value = "";
      updateList("detailLoading");
    };

    /**
     * @description: 表格筛选时
     */
    const onRowsSelectChange = (selection) => {
      let ids: number[] = [];
      selection.forEach((item) => {
        ids.push(item.id);
      });
      checkedArr.value = ids;
    };

    /**
     * 数据计算相关
     *
     *
     */
    const totalTransactionNetAmount = (amount) => {
      let million = 1000000,
        isMillion = amount > million,
        billion = 1000000000,
        isBillion = amount > billion,
        unit = isBillion ? "B" : isMillion ? "M" : "";
      if (isBillion) {
        amount = amount / billion;
      } else if (isMillion) {
        amount = amount / million;
      }
      if (isMillion) {
        let amounts = String(amount).split("."),
          resetCount = "";
        amounts.map((item, index) => {
          if (index === 0) {
            resetCount += item;
          } else {
            resetCount += "." + item.slice(0, 2);
          }
        });
        amount = Number(resetCount);
      }
      return {
        unit,
        amount,
        isMillion,
        isBillion,
      };
    };

    const amountToParseInt = (amount) => {
      return amount < 1 ? amount : parseInt(amount);
    };

    const totalTransactionNetAmountC = useComputedFn(totalTransactionNetAmount);

    /**
     * 远程搜索相关
     *
     *
     */
    const getEntityRelationAgentAccounts = async (query: string) => {
      if (query) {
        options.value.sourceLoading = true;
        if (options.value.cancelSource) {
          options.value.cancelSource.cancel();
        }
        const cancelToken = axios.CancelToken;
        options.value.cancelSource = cancelToken.source();
        const { data } =
          await ApiEntityRelationships.getEntityRelationAgentAccounts(
            {
              agent_account_type: getTypeParams.value.agent_account_type,
              search_value: query,
              max_item: "all",
            },
            {
              cancelToken: options.value.cancelSource.token,
            }
          );

        options.value.sourceLoading = false;
        if (data.code == 0) {
          options.value.sourceOptions.splice(
            0,
            options.value.sourceOptions.length,
            ...data.data
          );
        }
      } else {
        options.value.sourceOptions.splice(
          0,
          options.value.sourceOptions.length,
          ...defaultSourceOptions.value
        );
      }
    };

    const debounceAgentAccountsSearch = _.debounce(
      getEntityRelationAgentAccounts,
      1000
    );

    /**
     * @description: 远程搜索供应商
     */
    const searchAgentAccountsItems = (query: string) => {
      debounceAgentAccountsSearch(query);
    };

    /**
     * 文件导出相关
     *
     *
     */
    const filterExportType = (type, flag = true) => {
      let params = {};
      if (type === "all") {
        if (flag) {
          disabledExportAll.value = true;
          params = {
            ...setCommonFilter.value,
          };
        } else {
          disabledExportAll.value = false;
        }
      } else if (type === "selected") {
        if (flag) {
          if (checkedArr.value.length > 0) {
            let arr = [...filterOptions.value, ...setOtherFilter.value];
            arr.push({
              field: "id",
              value: checkedArr.value,
              condition: "in",
            });
            params = {
              filter_group: arr,
            };
            disabledExportSelected.value = true;
          } else {
            return false;
          }
        } else {
          disabledExportSelected.value = false;
        }
      } else if (type === "lastWeek") {
        if (flag) {
          disabledExportLastWeek.value = true;
          params = {
            filter_group: lastWeekFilter.value.filter_group,
          };
        } else {
          disabledExportLastWeek.value = false;
        }
      } else if (type === "lastMonth") {
        if (flag) {
          disabledExportLastMonth.value = true;
          params = {
            filter_group: lastMonthFilter.value.filter_group,
          };
        } else {
          disabledExportLastMonth.value = false;
        }
      }
      if (flag) {
        return params;
      }
    };

    /**
     * @description: 文件导出时
     */
    const filesExport = async (type = "all") => {
      let idParams = filterExportType("all");
      const data = await getTypeParams.value.responseExport({
        ...idParams,
      });
      filterExportType(type, false);
      commonExportFile(data);
    };

    const fileExport = async (id, item) => {
      // item.downloadDisabled = true;
      // let arr = [...filterOptions.value, ...setOtherFilter.value];
      // arr.push({
      //   field: "id",
      //   value: [id],
      //   condition: "in",
      // });
      // const data = await ApiFinacialReport.exportSsrReport({
      //   filter_group: arr,
      // });
      // item.downloadDisabled = false;
      // commonExportFile(data);
    };

    const init = () => {
      setModuleBCN(t, route);
      updateList("detailLoading");
    };

    onMounted(() => {
      options.value.defaultSort.splice(
        0,
        options.value.defaultSort.length,
        ...sortOptions.value
      );
      defaultDate(false);
      options.value.filter_group = [...setCommonFilter.value.filter_group];
      init();
      // if (store.getters.getAliveList.length === 0) {
      //   init();
      // }
    });

    onActivated(() => {
      init();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      moment,
      formatUtcDate,
      formatDate,
      settlementLastWeekDate,
      settlementLastMonth,
      settlementLastMonthDate,
      detailLoading,
      tableLoading,
      statisticPicker,
      filterRef,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      sortOrder,
      sortLabel,
      options,
      isCanExport,
      disabledExport,
      disabledExportAll,
      disabledExportSelected,
      disabledExportLastWeek,
      disabledExportLastMonth,
      checkedArr,
      getDateType,
      dayShortcuts,
      monthShortcuts,
      yearShortcuts,
      disabledDate,
      disabledYear,
      getTypeParams,
      getTopGridColumnCount,
      defaultDate,
      updateTimeRange,
      totalTransactionNetAmount,
      totalTransactionNetAmountC,
      amountToParseInt,
      searchAgentAccountsItems,
      showDefaultOptions,
      updateList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      isDefaultView,
      reset,
      resetColumnSort,
      handleSortReset,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      handleSourceSelect,
      handleSourceBlur,
      onRowsSelectChange,
      filesExport,
      fileExport,
    };
  },
});
